<template>
  <nav class="header">
    <div class="wrapper">
      <a
        v-if="platform !== 'mobile'"
        :href="`https://ninecasinogo.com${$root.getLinkPayload()}`"
        class="logo"
      >
        <img src="@/assets/logo.svg" />
      </a>
      <a
        class="header-link casino-link"
        :href="`https://ninecasinogo.com${$root.getLinkPayload()}`"
      >
        {{ $t("casino") }}
      </a>
      <a
        v-if="platform === 'mobile'"
        :href="`https://ninecasinogo.com${$root.getLinkPayload()}`"
        class="logo"
      >
        <img src="@/assets/logo-mobile.svg" />
      </a>
      <a
        class="header-link"
        :href="`https://ninecasinogo.com/sportsbook${$root.getLinkPayload()}`"
      >
        {{ $t("sports") }}
      </a>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Header",
  props: {
    platform: {
      type: String,
      default: "desktop"
    }
  }
};
</script>

<style lang="scss">
.header {
  position: sticky;
  top: 0;
  flex-shrink: 0;
  background: var(--main-color);

  .wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;

    .mobile & {
      justify-content: space-between;
      height: 54px;
    }
  }
}

.logo {
  display: block;
}

.header-link {
  color: var(--link-color);
  text-decoration: none;
}

.casino-link {
  margin-left: auto;
  margin-right: 20px;

  .mobile & {
    margin-left: 0;
  }
}
</style>
