<template>
  <div class="home" :class="platform">
    <Header :platform="platform" />
    <MainContent />
  </div>
</template>

<script>
import Header from "@/components/Header";
import MainContent from "@/components/MainContent";
import {throttle} from "lodash-es";

export default {
  name: "Home",
  components: {
    Header,
    MainContent,
  },
  data() {
    return {
      width: 0,
    }
  },
  computed: {
    platform() {
      if (this.width < 768) return 'mobile';
      if (this.width < 1280) return 'desktop tablet';
      if (this.width < 1440) return 'desktop min-desktop';
      return 'desktop max-desktop';
    }
  },
  mounted() {
    const updateWidth = throttle(() => this.width = window.innerWidth, 150);
    updateWidth();
    window.addEventListener('resize', () => {
      console.log(window.innerWidth);
      updateWidth();
    } );
  }
}
</script>

