<template>
  <div class="content">
    <div class="content-wrapper">
      <h1 class="title">
        {{ $t("title") }}
        <span class="title-attention">
          {{ $t("bonus") }}
        </span>
      </h1>
      <div class="text">
        {{ $t("mainText") }}
      </div>
      <a
        class="btn"
        :href="`https://ninecasinogo.com/sportsbook?open=registration${$root.getLinkPayload(
          '&'
        )}`"
      >
        {{ $t("btn") }}
      </a>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";

export default {
  name: "MainContent",
  components: {
    Footer
  },
  created() {
    console.log(this.$root);
  }
};
</script>

<style lang="scss">
.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-image: url(../assets/bg-desktop.jpg);
  background-repeat: no-repeat;
  background-position: right -5px;

  .mobile & {
    background-image: url(../assets/bg-mobile.jpeg);
    background-position: center -55px;
  }

  .desktop & {
    background-size: cover;
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;

    .mobile & {
      max-width: 320px;
      margin: 0 auto;
      padding-left: 16px;
      padding-bottom: 24px;
      padding-right: 16px;
      text-align: center;
    }

    .desktop & {
      flex: 1;
    }

    .tablet & {
      width: 50%;
      padding-top: 50px;
      padding-left: 24px;
    }

    .min-desktop & {
      width: 45%;
      padding-top: 120px;
      padding-left: 42px;
    }

    .max-desktop & {
      width: 50%;
      padding-top: calc(133px / 1440px * 100%);
      padding-left: 42px;

      @media (min-width: 1680px) {
        width: 45%;
        padding-left: 112px;
      }
    }
  }
}

.title {
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 800;
  line-height: 1;
  color: var(--text-color);
  text-transform: uppercase;

  .mobile & {
    font-size: 28px;
    line-height: 1.2;
  }

  .tablet & {
    font-size: 26px;
  }

  .min-desktop & {
    font-size: 29px;
  }

  .max-desktop & {
    font-size: 39px;
  }

  &-attention {
    display: block;
    line-height: 1;

    .mobile & {
      font-size: 43px;
    }

    .tablet & {
      font-size: 62px;
    }

    .min-desktop & {
      font-size: 72px;
    }

    .max-desktop & {
      font-size: 92px;
    }
  }
}

.text {
  font-size: 22px;
  font-weight: 600;
  color: var(--text-color);

  .mobile & {
    font-size: 15px;
    line-height: 18px;
  }

  .tablet & {
    font-size: 19px;
  }

  .min-desktop & {
    font-size: 22px;
  }

  .max-desktop & {
    font-size: 29px;
  }
}

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 288px;
  font-size: 23px;
  font-weight: 600;
  text-decoration: none;
  color: #000;
  background: radial-gradient(
    70.57% 75.64% at 22.42% 36.2%,
    #fce439 0%,
    #feb502 100%
  );
  border-radius: 1000px;

  .mobile & {
    height: 60px;
    margin-top: 276px;
  }

  .desktop & {
    height: 60px;
    margin-top: 54px;
  }

  .max-desktop & {
    height: 80px;
  }
}
</style>
