import Vue from "vue";
import App from "./App.vue";
import i18n from "./plugins/i18n";
import router from "./router";

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
  i18n,
  router,
  computed: {
    linkPayload() {
      const payload = window.location.search;
      return payload.slice(1) || "";
    }
  },
  methods: {
    getLinkPayload(alias = "?") {
      return this.linkPayload ? alias + this.linkPayload : "";
    }
  }
}).$mount("#app");
