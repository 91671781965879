<template>
  <footer class="footer">
    <div class="partners">
      <div class="wrapper">
        <img class="icon visa" src="@/assets/visa.svg" />
        <img class="icon skrill" src="@/assets/skrill.svg" />
        <img class="icon btransfer" src="@/assets/btransfer.svg" />
        <img class="icon mifinity" src="@/assets/mifinity.svg" />
        <img class="icon mastercard" src="@/assets/mastercard.svg" />
        <img class="icon banking" src="@/assets/banking.svg" />
        <img class="icon bitkoin" src="@/assets/bitkoin.svg" />
        <img class="icon paysafecard" src="@/assets/paysafecard.svg" />
      </div>
    </div>
    <div class="license wrapper">
      <img class="curacao" width="100" height="35" src="@/assets/curacao.svg" alt="curacao-icon" />
      <img class="icon18" width="24" height="24" src="@/assets/18.svg" alt="18-years-old-icon" />
      <img class="ssl" width="51" height="22" src="@/assets/ssl.svg" ald-="ssl-icon" />
    </div>
  </footer>
</template>

<script>
export default {
name: "Footer"
}
</script>

<style lang="scss">
.footer {
  flex-shrink: 0;
  width: 100%;

  .desktop & {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .partners {
    .mobile & {
      padding: 20px 0;
      background: var(--color-bg);
    }

    .desktop & {
      order: 1;
      margin-left: 25px;
    }

    .wrapper {
      .mobile & {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }

    .icon {
      margin-right: 20px;

      .mobile & {
        &:nth-child(-n + 4) {
          margin-bottom: 25px;
        }

        &:nth-child(4n) {
          margin-right: 0;
        }
      }
    }

    .visa {
      width: 50px;

      .mobile & {
        width: 39px;
      }
    }

    .mastercard {
      width: 35px;

      .mobile & {
        width: 28px;
      }
    }

    .banking {
      width: 68px;

      .mobile & {
        width: 54px;
      }
    }

    .skrill {
      width: 46px;

      .mobile & {
        width: 37px;
      }
    }

    .btransfer {
      width: 60px;

      .mobile & {
        width: 56px;
      }
    }

    .bitkoin {
      width: 30px;

      .mobile & {
        width: 20px;
      }
    }

    .paysafecard {
      width: 118px;

      .mobile & {
        width: 94px;
      }
    }

    .mifinity {
      width: 68px;

      .mobile & {
        width: 62px;
      }
    }
  }

  .license {
    display: flex;
    align-items: center;
    height: 67px;
    margin: 0;

    .mobile & {
      justify-content: center;
      margin: 0 auto;
    }

    .curacao {
      margin-right: 40px;
    }

    .icon18 {
      margin-right: 16px;
    }
  }
}
</style>
