<template>
  <router-view />
</template>

<script>
export default {
  name: "App"
};
</script>

<style lang="scss">
@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/Montserrat-ExtraBold.woff2") format("woff2"),
    url("assets/fonts/Montserrat-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/Montserrat-SemiBold.woff2") format("woff2"),
    url("assets/fonts/Montserrat-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

:root {
  --main-color: #161d37;
  --text-color: #ffffff;
  --link-color: #a2a5af;
  --color-bg: #24254e;
}

html {
  min-height: 100%;
  //min-height: -moz-available;
  //min-height: -webkit-fill-available;
  //min-height: fill-available;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  min-width: 320px;
  min-height: 100%;
  font-family: "Montserrat", sans-serif, monospace;
  font-weight: 600;
  background: #101131;
  -webkit-text-size-adjust: none;
}

a {
  text-decoration: none;
  color: inherit;
}

.home {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  //min-height: -moz-available;
  //min-height: -webkit-fill-available;
  //min-height: fill-available;
}

.wrapper {
  margin: 0 auto;

  .mobile & {
    max-width: 320px;
    padding: 0 16px;
  }

  .tablet & {
    padding: 0 24px;
  }

  .min-desktop & {
    padding: 0 42px;
  }

  .max-desktop & {
    padding: 0 42px;

    @media (min-width: 1680px) {
      padding: 0 112px;
    }
  }
}
</style>
